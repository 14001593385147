.divider-box{
  padding: 50px 0 50px;
}

.divider-simple{
  padding: 50px 0 50px;
}

.divider-grey{
  padding: 50px 0px 50px 0px;
  background-color: #F5F6FA;
 // border: 1px solid #dedede;
  //box-shadow: inset 0 1px 1px white;
  margin:0;
}

.divider-white{
  padding: 50px 0px 50px 0px;
  background-color: #fff;
}

.divider-grey0{
  background: #EDF1F2;
  padding:0;
}

//.divider-image1{
//  background-image: url('../images/background-blue.jpg');
//  background-repeat: no-repeat;
// //background-attachment: fixed;
//  background-size: cover;
//
//  h1,h2,h3,p,a{
//    color: #fff;
//  }
//}


.divider-darkgrey{
  background: #6E7C85;
}

.divider-gradientblue{
  padding: 50px 0 30px;
  background: rgb(52,52,52);
  background: -moz-linear-gradient(142deg, rgba(52,52,52,1) 0%, rgba(38,45,68,1) 100%);
  background: -webkit-linear-gradient(142deg, rgba(52,52,52,1) 0%, rgba(38,45,68,1) 100%);
  background: linear-gradient(142deg, rgba(52,52,52,1) 0%, rgba(38,45,68,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#343434",endColorstr="#262d44",GradientType=1);

  color: #fff;
  h1,h2,h3,h4,a{color: #fff}
}

.divider-footerblue{
  padding: 30px 0 30px;
  background-color: @site-footer-bg;
  color: #fff;
  h1,h2,h3,h4,a{color: #fff}
}


.divider-testimonials{
  padding: 20px 0;
 // background-image: url('../images/blackfamily.jpg');
 // background-position: 50% 50%;
  background-size: cover;
  margin:0;

  h1,h2,h3,p{
    color: #fff;
  }

}

.divider-black{
  padding: 20px 0;
  background: #1C1E1E;
  h1,h2,h3, a{
    color:#fff;
  }
}



.divider-firma{
  padding:0;
  .row,.col-sm-6{
    padding:0;
    margin:0;
  }
}

.firma-wrapper{
  padding: 20px;
  text-align:right;
  max-width: 600px;
  float:right;
  h1,h2,h3{
    text-align:right;
  }

  .firma-link{
    font-size:16px;
    display:inline-block;
    margin-left:30px;
  }
}


.container-title.title-right{

}

.divider-inpagesearch{
  padding:10px 0 5px 0;
  text-align:center;
  //background: @brand-color;
  background-color: #F5F6FA;
}