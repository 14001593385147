

.latest-complexes{
  h2{
    margin-top:0;
  }
}

.wrapper-more{
  margin-top:30px;
}
.complex-link{
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
}

.complexes-rows-wrapper{
  margin-top:30px;
}

.complex-row{
  position:relative;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.08);

  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;

  &:hover:after{
    width: 100%;
    opacity: 1;
  }

  &:before{
    display: table;
    content: " ";
  }
  .property-row-image{
    width:30%;
    height: auto;
    min-height: 160px;
    position: relative;
    background-color: rgba(0,0,0,.15);
    display: block;
    float: left;
    img{
      display: block;
      position: relative;
      width: 100%;
      z-index: 2;
    }
  }

  .property-row-content{
    width:70%;
    margin:0;
    padding:0 20px;
    float: left;
    min-height: 195px;
    position: relative;

  }
}

.complex-box{
  position:relative;
}
.complex-box-image{
  min-height:270px;
}

.complex-box-content{

  #gradient > .vertical(rgba(0,0,0,0), #000);
  color: #fff;
  position:absolute;
  bottom:0;
  left:0;right:0;
  padding:10px;

  h1,h2,h3,h4,h5, p, a{
    color: #fff;
    padding:0;
    margin:0;
    font-size:16px;
  }

  .complex-box-title{
    height: 25px;
    overflow: hidden;
  }

}

.complex-wrapper{
  margin-top: 30px;
}


@media (max-width: 991px){

  .complex-row{
    .property-row-image{
      width: 100%;
      max-width: 400px;
    }

    .property-row-content{
      width:100%;
    }
  }
}