#google_translate_element{
// float:left;
  display: none;

  a{
    color: #333;
  }
}

.language-switcher{
  position:relative;
  float:right;
  margin-top:20px;
  ul{
    list-style-type: none;
    li{
      float: left;
      a {
        padding: 3px 5px;
       // color: #000;
        margin-left: 5px;
        color: #000;
      //  text-indent:-9999px;
      }
    }
  }
}



.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  //background: url('../images/flags.png') no-repeat;
}


.flag.flag-de {
  background-position: 0 0;
}

.flag.flag-hu {
  background-position: 0 -24px;
}

.flag.flag-fr {
  background-position: -24px 0;
}

.flag.flag-gb {
  background-position: -48px 0;
}

.flag.flag-ro {
  background-position: -24px -24px;
}
